<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-11-22 14:37:29
 * @LastEditTime: 2022-12-07 17:39:37
 * @LastEditors: cyy
 * @Description: 新建秒杀
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\seckill\newSeckill.vue
-->
<template>
  <div class="newSeckill">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      :disabled="able == 2"
    >
      <el-form-item label="活动名称" :required="true">
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入活动名称"
          style="width:554px"
          show-word-limit
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item label="秒杀商品" :required="true">
        <el-button
          class="mb20"
          type="primary"
          :disabled="ruleForm.course.length > 0"
          @click="addCourseBool = true"
        >
          + 添加商品
        </el-button>
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
            padding: 0,
          }"
          :data="ruleForm.course"
        >
          <el-table-column label="商品名称">
            <template slot-scope="{ row }">
              <div class="contain">
                <div class="tu">
                  <img :src="row.photo || row.cover_img" alt="" />
                </div>
                <div class="right ">
                  <div class="title hidetext" :title="row.name">
                    {{ row.name }}
                  </div>
                  <div class="price">￥{{ row.price }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="{ row }">
              <span v-if="getcourseType(row) == 1">
                {{ row.course_type | courseTypeText }}
              </span>
              <span v-if="getcourseType(row) == 2">图文</span>
              <span v-if="getcourseType(row) == 3">
                {{ row.goods_class == 1 ? '知识商品' : '外部商品' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <el-button type="text" @click="addCourseBool = true">
              更换
            </el-button>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="秒杀价设置" :required="true">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
            padding: 0,
          }"
          :data="ruleForm.course"
        >
          <el-table-column label="原价(元)">
            <template slot-scope="{ row }">
              {{ row.price }}
            </template>
          </el-table-column>
          <el-table-column label="秒杀价(元)">
            <template slot-scope="{}">
              <el-input-number
                :key="1"
                v-model="ruleForm.seckill_price"
                style="width:168px"
                :step="0.01"
                :step-strictly="true"
                :precision="2"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="剩余数量(件)">
            <template slot-scope="{}">
              <el-input-number
                :key="2"
                style="width:168px"
                v-model="ruleForm.stock_num"
                :step="1"
                :precision="0"
                :step-strictly="true"
                placeholder="0为已抢光"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="活动时间" :required="true">
        <el-date-picker
          v-model="time"
          :picker-options="pickerOptions"
          type="datetimerange"
          value-format="timestamp"
          @change="timeChange"
          format="yyyy-MM-dd HH:mm "
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="活动标签">
        <el-input
          maxlength="5"
          v-model="ruleForm.seckill_tag"
          style="width:160px"
          placeholder="请输入活动标签"
        ></el-input>
        <div class="tips">
          活动期间展示于商品详情的价格旁边，2至5个字
          <el-popover placement="top-start" trigger="hover" width="300px">
            <img
              style="width:100%;height: 300px; "
              src="~@ass/img/1.4.28/tapd_23590331_base64_1669802296_85.png"
              alt=""
            />
            <span slot="reference" class="ml30 example">查看示例</span>
          </el-popover>
        </div>
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      style="width:120px"
      @click="submit"
      :disabled="able == 2"
    >
      保存
    </el-button>
    <networkschoolSelect
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="updateCourse"
      title="添加商品"
      v-if="addCourseBool"
      :goodlistType="true"
      :dialogstatus.sync="addCourseBool"
      :template="17"
      :singleselect="true"
      :is_seckill="true"
      :seckill_id="seckill_id"
      :goods_class="1"
    ></networkschoolSelect>
  </div>
</template>

<script>
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import bg from '@/mixin/background'
export default {
  mixins: [bg],

  components: {
    networkschoolSelect,
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },
      time: null,
      addCourseBool: false,
      ruleForm: {
        name: '',
        stock_num: undefined,
        seckill_price: undefined,
        course: [],
        goodsList: {
          goods_type: '',
          goods_id: '',
        },
        start_time: '',
        end_time: '',
        seckill_tag: '秒杀',
      },
    }
  },

  computed: {
    seckill_id() {
      return this.$route.query.id
    },
    able() {
      return this.$route.query.able
    },
  },

  created() {
    if (this.seckill_id) {
      this.getSeckillDetails()
      this.$root.$children[0].childPageOptions[1].name =
        this.able == 1 ? '编辑' : '查看'
    }
  },

  methods: {
    async getSeckillDetails() {
      const { data } = await this.$http({
        url: '/Seckill/getSeckillDetails',
        data: {
          seckill_id: this.seckill_id,
        },
      })
      this.ruleForm = _.assign({}, data, {
        course: [data.goodsList],
      })
      this.time = [data.start_time * 1000, data.end_time * 1000]
    },

    // 保存
    async submit() {
      if (!this.ruleForm.name.trim()) {
        this.$root.prompt('请输入活动名称')
        return
      }
      if (this.ruleForm.course.length == 0) {
        this.$root.prompt('请添加商品')
        return
      }

      if (!this.ruleForm.seckill_price) {
        this.$root.prompt('未设置秒杀价！')
        return
      }

      if (this.ruleForm.seckill_price <= 0) {
        this.$root.prompt('秒杀价不能小于0！')
        return
      }
      if (this.ruleForm.seckill_price > this.ruleForm.course[0].price) {
        this.$root.prompt('秒杀价不能超过原价')
        return
      }

      if (!this.ruleForm.stock_num) {
        this.$root.prompt('未设置剩余数量！')
        return
      }

      if (this.ruleForm.stock_num <= 0) {
        this.$root.prompt('剩余数量不能小于0！')
        return
      }

      if (this.ruleForm.stock_num > 100000) {
        this.$root.prompt('剩余数量不能大于100000！')
        return
      }

      if (!this.time) {
        this.$root.prompt('请选择活动时间')
        return
      }

      if (this.ruleForm.seckill_tag.length == 1) {
        this.$root.prompt('活动标签2至5个字')
        return
      }

      if (this.seckill_id) {
        await this.$http({
          url: '/Seckill/editSeckill',
          data: _.assign({}, this.ruleForm, { seckill_id: this.seckill_id }),
        })
      } else {
        await this.$http({
          url: '/Seckill/addSeckill',
          data: this.ruleForm,
        })
      }
      this.$router.push({ path: '/seckill' })
    },

    timeChange(val) {
      this.ruleForm.start_time = val ? val[0] / 1000 : ''
      this.ruleForm.end_time = val ? val[1] / 1000 : ''
    },

    // 获取课程类型
    getcourseType(val) {
      let num = 0
      if ('course_id' in val) {
        num = 1
      } else if ('graphic_id' in val) {
        num = 2
      } else if ('live_goods_id' in val) {
        num = 3
      }
      return num
    },

    updateCourse(val) {
      this.ruleForm.course = val
      const obj = val[0]
      if ('course_id' in obj) {
        this.ruleForm.goodsList.goods_id = obj.course_id
        this.ruleForm.goodsList.goods_type = obj.course_type
      } else if ('graphic_id' in obj) {
        this.ruleForm.goodsList.goods_id = obj.graphic_id
        this.ruleForm.goodsList.goods_type = 6
      } else if ('live_goods_id' in obj) {
        this.ruleForm.goodsList.goods_id = obj.live_goods_id
        this.ruleForm.goodsList.goods_type = 8
      }
    },
    closeGoodsConfirm() {
      this.addCourseBool = false
    },
  },
}
</script>

<style lang="scss" scoped>
.newSeckill {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  ::v-deep .el-divider {
    background-color: #1b9d97 !important;
  }
  .tips {
    font-size: 14px;
    color: #333333;
  }
  .example {
    font-size: 14px;
    color: #3d80ef;
    cursor: pointer;
  }
  .contain {
    display: flex;
    align-content: center;
    .tu {
      flex-shrink: 0;
      width: 68px;
      height: 41px;
      background: #d0d0d0;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        margin-right: 10px;
      }
    }
    .right {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 13px;
        font-weight: bold;
        color: #333333;
      }
      .price {
        font-size: 12px;
        color: #ff3535;
      }
    }
  }
}
</style>
